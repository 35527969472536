import React from 'react';
import {gsap} from "gsap";
import { closestEdge } from '../images/scripts/utils.js';
import {Link} from "gatsby";

import ipad1 from '../images/birdeye/ipad1.png';
import ipad2 from '../images/birdeye/ipad2.png';
import phones from '../images/birdeye/phones.png';
import lawn2 from '../images/lawn/march2021-cover.png';
import soi2019Cover from "../images/lawn/soi2019.png";
import blockchain from "../images/cannabis/blockchain.png";
import boomers from "../images/cannabis/september2019-boomers.png";
import ambulance from "../images/cannabis/ambulance.png";
import qa11 from "../images/qa/fsmahumanfood-marchapril.jpg";
import qa3 from "../images/qa/qa-sept2021.png";
import qa5 from "../images/qa/ai-mayjune.jpg";
import census3 from "../images/census/landscapercensus3.png";
import ngiste from "../images/cannabis/ngiste-abebe.png";
import troy from "../images/cannabis/troy-meadows.png";
import census2 from "../images/census/landscapercensus2.png";

export class MenuItem {
    constructor(el) {
        // .menu__item element
        this.DOM = {el: el};
        // .menu__item-link element
        this.DOM.link = this.DOM.el.querySelector('a.menu__item-link');
        // .marquee element
        this.DOM.marquee = this.DOM.el.querySelector('.marquee');
        // .marquee__inner-wrap element
        this.DOM.marqueeInner = this.DOM.marquee.querySelector('.marquee__inner-wrap');
        // some default options for the animation's speed and easing
        this.animationDefaults = {duration: 0.6, ease: 'expo'};
        // events initialization
        this.initEvents();
    }
    initEvents() {
        this.onMouseEnterFn = ev => this.mouseEnter(ev);
        this.DOM.link.addEventListener('mouseenter', this.onMouseEnterFn);
        this.onMouseLeaveFn = ev => this.mouseLeave(ev);
        this.DOM.link.addEventListener('mouseleave', this.onMouseLeaveFn);
    }
    mouseEnter(ev) {
        // find closest side to the mouse
        const edge = this.findClosestEdge(ev);

        // set the initial y position for both the marquee and marqueeInner elements
        // for the reveal effect to happen, both start at opposite positions
        // the directions are different depending on the direction the cursor enters the element (bottom or top)
        gsap.timeline({defaults: this.animationDefaults})
                .set(this.DOM.marquee, {y: edge === 'top' ? '-101%' : '101%'}, 0)
                .set(this.DOM.marqueeInner, {y: edge === 'top' ? '101%' : '-101%'}, 0)
                .to([this.DOM.marquee, this.DOM.marqueeInner], {y: '0%'}, 0);
    }
    mouseLeave(ev) {
        // find closest side to the mouse
        const edge = this.findClosestEdge(ev);

        gsap.timeline({defaults: this.animationDefaults})
                .to(this.DOM.marquee, {y: edge === 'top' ? '-101%' : '101%'}, 0)
                .to(this.DOM.marqueeInner, {y: edge === 'top' ? '101%' : '-101%'}, 0);
    }
    // find closest side to the mouse when entering/leaving
    findClosestEdge(ev) {
        const x = ev.pageX - this.DOM.el.offsetLeft;
        const y = ev.pageY - this.DOM.el.offsetTop;
        return closestEdge(x,y,this.DOM.el.clientWidth, this.DOM.el.clientHeight);
    }
}

export class MenuTest {
    constructor(el) {
        // .menu element
        this.DOM = {el: el};
        // the menu items
        this.DOM.menuItems = this.DOM.el.querySelectorAll(".menu__item");
        // array of MenuItem
        this.menuItems = [];
        this.DOM.menuItems.forEach(menuItem => this.menuItems.push(new MenuItem(menuItem)));
    }
}

class Menu extends React.Component {
    componentDidMount() {
        // initialize the menu
        new MenuTest(document.querySelector('.menu'));
    }

    render() {
        return(
            <nav className="menu">
                <div className="menu__item">
                    <Link to="/work/lawn-landscape" className="menu__item-link font-serif">Lawn & Landscape</Link>
                    <div className="marquee bg-blue">
                        <div className="marquee__inner-wrap">
                            <div className="marquee__inner" aria-hidden="true">
                                <span className="font-serif">Lawn & Landscape</span>
                                <div className="marquee__img" style={{ backgroundImage: `url(${soi2019Cover})` }} />
                                <span className="font-serif">Lawn & Landscape</span>
                                <div className="marquee__img" style={{ backgroundImage: `url(${lawn2})` }} />
                                <span className="font-serif">Lawn & Landscape</span>
                                <div className="marquee__img" style={{ backgroundImage: `url(${lawn2})` }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="menu__item">
                    <Link to="/work/birdeye" className="menu__item-link font-serif">BirdEye</Link>
                    <div className="marquee bg-pink">
                        <div className="marquee__inner-wrap">
                            <div className="marquee__inner" aria-hidden="true">
                                <span className="font-serif">BirdEye</span>
                                <div className="marquee__img" style={{ backgroundImage: `url(${phones})` }} />
                                <span className="font-serif">BirdEye</span>
                                <div className="marquee__img" style={{ backgroundImage: `url(${ipad2})` }} />
                                <span className="font-serif">BirdEye</span>
                                <div className="marquee__img" style={{ backgroundImage: `url(${ipad1})` }} />
                                <span className="font-serif">BirdEye</span>
                                <div className="marquee__img" style={{ backgroundImage: `url(${phones})` }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="menu__item">
                    <Link to="/work/cannabis-dispensary" className="menu__item-link font-serif">Cannabis Dispensary</Link>
                    <div className="marquee bg-yellow">
                        <div className="marquee__inner-wrap">
                            <div className="marquee__inner" aria-hidden="true">
                                <span className="font-serif">Cannabis Dispensary</span>
                                <div className="marquee__img" style={{ backgroundImage: `url(${blockchain})` }} />
                                <span className="font-serif">Cannabis Dispensary</span>
                                <div className="marquee__img" style={{ backgroundImage: `url(${ambulance})` }} />
                                <span className="font-serif">Cannabis Dispensary</span>
                                <div className="marquee__img" style={{ backgroundImage: `url(${boomers})` }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="menu__item">
                    <Link to="/work/quality-assurance" className="menu__item-link font-serif">Quality Assurance</Link>
                    <div className="marquee bg-black">
                        <div className="marquee__inner-wrap">
                            <div className="marquee__inner" aria-hidden="true">
                                <span className="font-serif">Quality Assurance</span>
                                <div className="marquee__img" style={{ backgroundImage: `url(${qa3})` }} />
                                <span className="font-serif">Quality Assurance</span>
                                <div className="marquee__img" style={{ backgroundImage: `url(${qa5})` }} />
                                <span className="font-serif">Quality Assurance</span>
                                <div className="marquee__img" style={{ backgroundImage: `url(${qa11})` }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="menu__item">
                    <Link to="/work/misc" className="menu__item-link font-serif">Grab Bag</Link>
                    <div className="marquee bg-grey">
                        <div className="marquee__inner-wrap">
                            <div className="marquee__inner text-black" aria-hidden="true">
                                <span className="font-serif text-black">Grab Bag</span>
                                <div className="marquee__img" style={{ backgroundImage: `url(${ngiste})` }} />
                                <span className="font-serif text-black">Grab Bag</span>
                                <div className="marquee__img" style={{ backgroundImage: `url(${census3})` }} />
                                <span className="font-serif text-black">Grab Bag</span>
                                <div className="marquee__img" style={{ backgroundImage: `url(${troy})` }} />
                                <span className="font-serif text-black">Grab Bag</span>
                                <div className="marquee__img" style={{ backgroundImage: `url(${census2})` }} />
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Menu