import React from 'react';

import Layout from 'components/Layout';
import Container from 'components/Container';
import aboutLargeSquares from "../images/large-about-squares.png";
import Seo from "../components/Seo";
import Menu from "../components/Menu";

const IndexPage = () => {
  return (
    <Layout pageName="home">
        <Seo
                title={'Designer & Art Director'}
        />
      <Container>
          <Menu/>
          <div className="text-left grid md:grid-cols-6 pt-12">
              <div className="col-span-3 bg-black p-16 flex justify-center flex-col mr-0.5">
                  <h2 className="font-serif text-8xl text-white">Say hey!</h2>
                  <a href="mailto:jarmburg@gmail.com" className="font-mono text-2xl block mt-8 text-white contact-info hover:text-pink transition-colors duration-200">jarmburg@gmail.com</a>
              </div>
              <div className="col-span-3 md:-ml-32">
                  <img src={aboutLargeSquares} alt="Justin Armburger"/>
              </div>
          </div>
      </Container>
    </Layout>
  );
};

export default IndexPage;